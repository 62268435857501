<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <component v-bind:is="authStep" @authStep="updateAuthState"></component>
  </v-card>
</template>

<script>
import Login from "../../components/Auth/Login.vue";
import ChangePassword from "../../components/Auth/ChangePassword.vue";
import ResetPassword from "../../components/Auth/ResetPassword.vue";

export default {
  name: "sign-up-illustration",

  components: {
    Login,
    ChangePassword,
    ResetPassword,
  },

  data() {
    return {
      authStep: Login,
    };
  },

  methods: {
    updateAuthState(evt) {
      this.authStep = evt;
    },
  },
};
</script>
