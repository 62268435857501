import axios from "axios";

/**
 * COGNITO USER POOL DATA
 * @typedef {Object} COGNITO_USER_POOL
 * @property {string} awsAPI - The API address
 * @property {string} awsClientId - The client id
 * @property {string} awsUserPoolId - The user pool id
 * @property {string} dazleAPI - Api for dazle
 * @property {string} displayname - company name to display
 * @property {string} enterpriseId - company id
 * @property {string} id - company name in base64
 * @property {string} identityPoolId - pool id
 * @property {string} idsearch - company name in base64
 * @property {string} stackName - Name of the stack
 * @property {string} stackstatus - Could be "UPDATED" ...
 * @property {string} version - Get the version
 * @property {boolean} SSO -- True if SSO
 * @property {string} SSO_provider - SSO provider
 * @property {string} SSO_domain - SSO domain
 */

// const AUTHURL =
//   process.env.NODE_ENV === "production"
//     ? "https://clients.dynapark.io/v1/clients"
//     : "https://fxbn1xw7g3.execute-api.eu-west-2.amazonaws.com/devel/clients";

const AUTHURL = "https://clients.dynapark.io/v1/clients/";

export default {
  /**
   * URL used to get entreprise cognito pool config
   * To connect a user you need first to know the Cognito userpool config.
   * This route allow you to get these data when addiong the company name
   * in base64 format.
   * @returns { COGNITO_USER_POOL } poolinfo - All data needed to access cognito user pool
   */
  async getCognitoPool(entreprise) {
    const etsB64 = window.btoa(entreprise.trim());
    return axios.get(`${AUTHURL}${etsB64}`);
  },
};
