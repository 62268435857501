<template>
  <div>
    <div class="card-padding pb-0">
      <h4
        class="
          text-h2
          font-weight-bolder
          text-typo
          mb-2
          text-info text-gradient
        "
      >
        Modifiez votre mot de passe
      </h4>
      <p class="mb-0 text-body">Entrez un nouveau mot de passe</p>
    </div>
    <div class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Nouveau mot de passe</label
      >
      <v-text-field
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        id="password"
        v-validate="'required'"
        required="required"
        name="password"
        :class="{ 'is-invalid': submitted && errors.has('password') }"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Password"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>
      <p class="mb-0 input-error">{{ error }}</p>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-info
          bg-gradient-info
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="changeDefaultPassword"
        >Changer le mot de passe</v-btn
      >
    </div>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";

export default {
  name: "ChangePassword",

  data() {
    return {
      show1: false,
      submitted: false,
      password: "",
      error: "",
    };
  },

  methods: {
    changeDefaultPassword() {
      const user = this.$store.getters.cognitoUser;
      Auth.completeNewPassword(user, this.password.trim(), {})
        .then((data) => {
          this.userSignIn(data.username, this.password.trim());
        })
        .catch((e) => {
          console.error("ERROR on changeDefaultPassword", e.message);
          this.error = e.message;
        });
    },

    async userSignIn(username, newpass) {
      Auth.signIn(username, newpass)
        .then((data) => {
          this.$store.dispatch("setUser", data);
          this.$store.dispatch("setLogged", true);
          this.$router.push("/home");
        })
        .catch((e) => {
          if (e.code === "NotAuthorizedException") {
            this.error = "Mot de passe incorrect";
          } else if (e.code === "UserNotFoundException") {
            this.error = "Utilisateur inconnu";
          } else {
            this.error = e.message;
          }
        });
    },
  },
};
</script>

<style scoped></style>
