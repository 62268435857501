<template>
  <div>
    <div class="card-padding pb-0">
      <h4
        class="
          text-h2
          font-weight-bolder
          text-typo
          mb-2
          text-info text-gradient
        "
      >
        Réinitialiser votre mot de passe
      </h4>
    </div>
    <div v-if="!submitted" class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Entreprise</label
      >
      <v-text-field
        v-model="entreprise"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Entreprise"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1">Email</label>
      <v-text-field
        v-model="email"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Email"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>
      <p class="mb-0 input-error">{{ error }}</p>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-info
          bg-gradient-info
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        :disabled="!canResetPassword"
        @click="awsConfig"
        >RÉINITIALISER</v-btn
      >
    </div>

    <div v-if="submitted" class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Votre code temporaire, reçu par email</label
      >
      <v-text-field
        v-model="tempCode"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Code temporaire"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Votre nouveau mot de passe</label
      >
      <v-text-field
        v-model="password"
        :type="show1 ? 'text' : 'password'"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Nouveau mot de passe"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>
      <p style="color: red; font-size: 0.8em">{{ error }}</p>
      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-info
          bg-gradient-info
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="submitPassword"
        >Mettre à jour le mot de passe</v-btn
      >
    </div>
    <div class="back-to-login" @click="backToLogin">&larr; retour</div>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
import AuthAPI from "../../api/auth";

export default {
  name: "ResetPassword",

  data() {
    return {
      show1: false,
      submitted: false,
      entreprise: "",
      email: "",
      error: "",
      password: null,
      tempCode: null,
    };
  },

  computed: {
    canResetPassword() {
      return this.entreprise && this.email;
    },
  },

  methods: {
    async awsConfig() {
      localStorage.clear();
      this.loading = true;
      const companyName = this.entreprise.trim().toLowerCase();
      // -- Get Cognito pool data
      const cognitoPool = await AuthAPI.getCognitoPool(companyName);

      if (Object.keys(cognitoPool.data).length === 0) {
        console.warn(`This company does not exist: ${companyName}`);
        this.$store.dispatch("addError", "L'entreprise n'existe pas.");
        this.error = "L'entreprise n'existe pas.";
        this.loading = false;
        return;
      }
      this.$store.dispatch("setCognitoPool", cognitoPool.data);

      // -- Configure Auth
      const awsExports = {
        aws_project_region: "eu-central-1",
        aws_cognito_region: "eu-central-1",
        aws_user_pools_id: cognitoPool.data.awsUserPoolId,
        aws_user_pools_web_client_id: cognitoPool.data.awsClientId,
        authenticationFlowType: "USER_PASSWORD_AUTH",
        ready: true,
      };
      this.$store.dispatch("setAws", awsExports);
      this.$store.dispatch("setEnterprise", companyName);
      Auth.configure(awsExports);
      this.resetPassword();
    },

    resetPassword() {
      AuthAPI.getCognitoPool(this.entreprise.trim()).then((res) => {
        if (Object.keys(res.data).length === 0) {
          console.warn(`This company does not exist: ${this.entreprise}`);
          this.error = "Cette entreprise n'existe pas";
          return;
        }
      });

      Auth.forgotPassword(this.email.toLowerCase().trim())
        .then(() => {
          this.error = null;
          this.submitted = true;
        })
        .catch((e) => {
          console.log(e);
          if (e.code === "UserNotFoundException") {
            this.error = "Utilisateur non trouvé.";
          } else {
            this.error = e.message;
          }
        });
    },

    submitPassword() {
      Auth.forgotPasswordSubmit(
        this.email.toLowerCase().trim(),
        this.tempCode,
        this.password
      )
        .then(() => {
          this.error = null;
          this.$emit("authStep", "Login");
        })
        .catch((e) => {
          if (e.code === "UserNotFoundException") {
            this.error = "L'utilisateur n'existe pas";
          } else if (e.code === "InvalidParameterException") {
            this.error = "Le mot de passe n'est pas valide";
          } else if (e.code === "CodeMismatchException") {
            this.error =
              "Le code temporaire n'est pas valide, veuillez ressayer.";
          } else {
            this.error = e.message;
          }
        });
    },

    backToLogin() {
      console.log("authStep");
      this.$emit("authStep", "Login");
    },
  },
};
</script>

<style scoped>
.back-to-login {
  color: rgba(0, 0, 0, 0.3);
}

.back-to-login:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}
</style>
