<template>
  <div>
    <div class="card-padding pb-0">
      <h4
        class="
          text-h2
          font-weight-bolder
          text-typo
          mb-2
          text-info text-gradient
        "
      >
        Se connecter
      </h4>
      <p class="mb-0 text-body">Entrez vos identifiants pour vous connecter</p>
    </div>
    <div
      v-if="loading || this.$store.getters.isLogging"
      class="grid-center-h-v"
    >
      <v-progress-circular
        :size="32"
        :width="2"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1"
        >Entreprise</label
      >
      <v-text-field
        v-model="entreprise"
        background-color="rgba(255,255,255,.9)"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
        color="rgba(0,0,0,.6)"
        height="40"
        hide-details
        light
        outlined
        placeholder="Entreprise"
      >
      </v-text-field>

      <div v-if="company_selected && !sso_login">
        <label class="text-xs text-typo font-weight-bolder ms-1">Email</label>
        <v-text-field
          v-model="email"
          background-color="rgba(255,255,255,.9)"
          class="
            input-style
            font-size-input
            border border-radius-md
            placeholder-lighter
            text-color-light
            mb-4
            mt-2
          "
          color="rgba(0,0,0,.6)"
          height="40"
          hide-details
          light
          outlined
          placeholder="Email"
        >
        </v-text-field>

        <label class="text-xs text-typo font-weight-bolder ms-1"
          >Password</label
        >
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          background-color="rgba(255,255,255,.9)"
          class="
            input-style
            font-size-input
            border border-radius-md
            placeholder-lighter
            text-color-light
            mb-4
            mt-2
          "
          color="rgba(0,0,0,.6)"
          counter
          height="40"
          hide-details
          hint="At least 8 characters"
          light
          name="input-10-1"
          outlined
          placeholder="Password"
          @click:append="show1 = !show1"
        >
        </v-text-field>
      </div>
      <!-- <v-checkbox
        v-model="checkbox"
        color="#141727"
        :ripple="false"
        class="ma-0 checkbox-custom checkbox-thinner"
        hide-details
      >
        <template v-slot:label>
          <span class="text-typo text-body-2 ls-0"
            >I agree the
            <a
              href="javascript:;"
              class="text-dark font-weight-bolder text-decoration-none"
              >Terms and Conditions</a
            ></span
          >
        </template>
      </v-checkbox> -->
      <p class="mb-0 input-error">{{ errorMessage }}</p>
      <div v-if="!company_selected">
        <v-btn
          :ripple="false"
          class="
            font-weight-bold
            text-uppercase
            btn-info
            bg-gradient-info
            py-2
            px-6
            me-2
            mt-7
            mb-2
            w-100
          "
          color="#5e72e4"
          elevation="0"
          height="43"
          small
          @click="selectCompany(false)"
          >Sélectionner l'entreprise
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          :ripple="false"
          class="
            font-weight-bold
            text-uppercase
            btn-info
            bg-gradient-info
            py-2
            px-6
            me-2
            mt-7
            mb-2
            w-100
          "
          color="#5e72e4"
          elevation="0"
          height="43"
          small
          @click="userSignIn"
          >Se connecter
        </v-btn>
      </div>

      <div v-if="company_selected && !sso_login" class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          Vos identifiants sont perdus?
          <a
            class="
              text-info text-gradient text-decoration-none
              font-weight-bold
            "
            href="javascript:"
            @click="resetPassword"
            >Cliquez ici</a
          >
        </p>
      </div>
    </div>

    <div v-if="this.$store.getters.isLogging" class="grid-center-h-v">
      <v-btn
        :ripple="false"
        class="
          font-weight-bold
          text-uppercase
          btn-info
          bg-gradient-info
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        elevation="0"
        height="43"
        small
        @click="cancelLogging"
        >Annuler
      </v-btn>
    </div>
  </div>
</template>

<script>
// import Auth from "@aws-amplify/auth";
import AuthAPI from "../../api/auth";
import Amplify, { Auth, Hub, Logger } from "aws-amplify";
import { jwtDecode } from "jwt-decode";
import store from "@/store";

// Configure Amplify
const logger = new Logger("auth", "DEBUG");

export default {
  name: "Login",

  data() {
    return {
      loading: false,
      show1: false,
      checkbox: false,
      entreprise: null,
      company_selected: false,
      sso_login: false,
      email: null,
      password: "",
      pwd_type: "password",
      loginFailMessage: false,
      error: null,
      locale: "fr",
      errorMessage: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  beforeMount() {
    const autoLogin = this.$route.query;
    // console.log(`before mount - isLogging: ${this.$store.getters.isLogging}`);
    // console.log(
    //   `before mount - autologin: ${autoLogin.ets} ${autoLogin.mail} ${autoLogin.pass}`
    // );
    if (autoLogin.ets && autoLogin.mail && autoLogin.pass) {
      console.log("auto login");
      this.autoLoginMethod(autoLogin);
    } else if (this.$store.getters.isLogged) {
      console.log("user logged");
      this.$router.push("/home");
    }
  },
  created() {
    this.initializeAuthHub();
  },
  methods: {
    async autoLoginMethod(autoLogin) {
      console.log("auto login");
      this.entreprise = autoLogin.ets;
      this.email = autoLogin.mail;
      this.password = autoLogin.pass;
      await this.$store.dispatch("logoutUser");
      this.company_selected = true;
      this.sso_login = false;
      await this.selectCompany(true);
      this.loading = true;
      await this.userSignIn();
      console.log("autologin completed");
    },
    initializeAuthHub() {
      // Set up Hub listener for authentication events
      Hub.listen("auth", this.authListener);
    },
    cancelLogging() {
      this.$store.dispatch("setLogging", false);
    },
    authListener(capsule) {
      const { channel, payload, source } = capsule;
      logger.debug("Auth event detected", capsule, source);

      if (channel === "auth") {
        switch (payload.event) {
          case "signIn":
            console.log("User signed in: ", payload);
            this.handleSignIn(payload.data);
            break;
          case "signUp":
            console.log("User signed up");
            this.handleSignIn(payload.data);
            break;
          case "signOut":
            console.log("User signed out");
            this.handleSignOut();
            break;
          case "signIn_failure":
            console.log("User sign in failed");
            break;
          case "configured":
            console.log("Auth module configured");
            break;
          default:
            console.log("Auth event:", payload.event);
            break;
        }
      }
    },
    handleSignIn(user) {
      console.log("Handling user sign-in", user);
      // console.log(
      //   "User signed in, idToken:",
      //   user.signInUserSession.idToken.jwtToken
      // );
      const decodedIdToken = jwtDecode(user.signInUserSession.idToken.jwtToken);
      // console.log("User signed in, idToken:", decodedIdToken);
      user.attributes = decodedIdToken.attributes;

      this.$store.dispatch("setUser", user);
      this.$store.dispatch("setLogged", true);
      this.$store.dispatch("setLogging", false);
      this.$router.push("/home");
    },
    handleSignOut() {
      console.log("Handling user sign-out");
      this.$store.dispatch("logoutUser");
      // Additional logic to handle user sign-out
    },
    async selectCompany(autoLogin) {
      // console.log("clear local storage");
      // localStorage.clear();
      this.loading = true;
      const companyName = this.entreprise.trim().toLowerCase();
      // -- Get Cognito pool data
      console.log(`getting company information (autologin: ${autoLogin})`);
      const cognitoPool = await AuthAPI.getCognitoPool(companyName);
      console.log(`got company info: ${JSON.stringify(cognitoPool.data)}`);
      console.log(`company is SSO: `, cognitoPool.data.SSO);

      if (Object.keys(cognitoPool.data).length === 0) {
        console.warn(`This company does not exist: ${companyName}`);
        await this.$store.dispatch("addError", "L'entreprise n'existe pas.");
        this.errorMessage = "L'entreprise n'existe pas.";
        this.loading = false;
        await this.$store.dispatch("setLogging", false);
        return;
      }
      this.company_selected = true;

      if (autoLogin) {
        console.log("autologin force direct login");
        cognitoPool.data.SSO = false;
      }

      let oauthInfo = null;
      // const app_url = "http://localhost:8080";
      const app_url = "https://dynapark.app";
      // const app_url = "https://web.dynapark.io";

      if (cognitoPool.data.SSO) {
        oauthInfo = {
          domain: cognitoPool.data.SSO_domain,
          scope: ["openid"], // Use only one 'scope' or 'scopes'
          redirectSignIn: app_url,
          redirectSignOut: app_url,
          responseType: "code",
          identity_provider: cognitoPool.data.SSO_provider,
        };
        this.sso_login = true;
      } else {
        this.sso_login = false;
      }
      console.log(
        `oauthInfo: ${JSON.stringify(oauthInfo)}, ${cognitoPool.data.SS0}`
      );

      const awsExports = {
        aws_project_region: "eu-west-1",
        aws_cognito_region: "eu-west-1",
        aws_user_pools_id: cognitoPool.data.awsUserPoolId,
        aws_user_pools_web_client_id: cognitoPool.data.awsClientId,
        authenticationFlowType: "USER_PASSWORD_AUTH",
        ready: true,
        oauth: oauthInfo,
      };

      console.log(
        `setting auth store, poolData: ${JSON.stringify(cognitoPool.data)}`
      );
      await this.$store.dispatch("setCognitoPool", cognitoPool.data);
      await this.$store.dispatch("setEnterprise", companyName);
      await this.$store.dispatch("setAws", awsExports);
      console.log("set auth store done");

      if (store.getters.cognitoPool.SSO) {
        console.log("SSO login");
        await this.$store.dispatch("setLogging", true);
        // don't need input form the user, start login with SSO
        await this.userSignIn();
      } else {
        // need user / password from user to login
        console.log("direct login");
        await this.$store.dispatch("setLogging", false);
        this.loading = false;
      }
    },
    /**
     * USER SIGNIN
     */
    async userSignIn() {
      const logger = new Logger("amplify-logger", "INFO");
      Amplify.configure({
        ...store.getters.awsExports,
        logger,
      });
      console.log("starting auth");

      if (store.getters.cognitoPool.SSO) {
        console.log("Starting auth with SSO");
        await Auth.federatedSignIn({
          provider: store.getters.cognitoPool.SSO_provider,
        });
        console.log("completed auth with SSO");
      } else {
        console.log("starting Auth with Cognito");
        Auth.signIn(this.email.toLowerCase().trim(), this.password)
          .then((data) => {
            console.log("AUTH --> ", data);
            if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
              this.$store.dispatch("setUser", data);
              this.$emit("authStep", "ChangePassword");
            } else if (data.challengeName === "RESET_REQUIRED") {
              this.errorMessage =
                "Le délai d'authentification à expiré.\nMerci de contacter un administrateur.";
            } else if (data.challengeName === undefined) {
              this.$store.dispatch("setUser", data);
              this.$store.dispatch("setLogged", true);
              this.$router.push("/home");
            }
          })
          .catch((e) => {
            console.log("AUTH: ", e);
            this.loading = false;
            if (e.code === "NotAuthorizedException") {
              if (
                e.message &&
                e.message ===
                  "Temporary password has expired and must be reset by an administrator."
              ) {
                this.errorMessage =
                  "Le délai d'authentification à expiré, contacter l'administrateur pour obtenir un nouveau mot de passe.";
              } else {
                this.errorMessage = "Mot de passe incorrect";
              }
            } else if (e.code === "UserNotFoundException") {
              this.errorMessage = "Utilisateur inconnu";
            } else {
              this.errorMessage = e.message;
            }
          });
      }
    },
    resetPassword() {
      this.$emit("authStep", "ResetPassword");
    },
  },
};
</script>

<style scoped></style>
